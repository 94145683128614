@mixin selected-nav-item {
  position: relative;
  border-radius: 0px;
  background-color: #ff4c30;
  color: #fff;
  overflow: visible;

  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }

  &:hover {
    color: #fff;
  }

  &:active {
    background-color: #ff4c30;
    border-radius: 0px;
  }

  .custom-badge {
    .ant-badge-count {
      background-color: #fff;
      color: #000;
    }
  }
}

@mixin nav-item {
  .ant-menu-title-content {
    margin-left: 25px;
    font-weight: 500;
    a {
      font-weight: 500;
    }
  }

  .anticon {
    min-width: 22px;
    min-height: 22px;
    font-size: 20px;
  }

  &::after {
    border: none;
  }

  //fill-svg
  &:hover {
    color: #ff4c30;
    a {
      color: #ff4c30;
    }
    &:active {
      background-color: transparent;
      border-radius: 7px;
    }
  }

  &:hover .ant-menu-submenu-arrow {
    color: #ff4c30;
  }
}

.app-sidebar {
  min-height: 100%;
  height: 100%;
  background-color: transparent;

  .main-menu {
    position: relative;
    flex-grow: 1;
    min-height: 100%;
    padding-top: 12px;
    border: none;
    background-color: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07), 0 3px 20px rgba(0, 0, 0, 0.1) !important;

    .nav-items {
      background-color: transparent;
      border-radius: 7px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      @include nav-item();

      .custom-badge {
        .ant-badge-count {
          top: -8px;
          right: -4px;
          min-width: 18px;
          height: 18px;
          padding: 0 5px;
          box-shadow: none;
          font-size: 12px;
          line-height: 18px;
        }
      }

      &:not(:active) {
        border-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &.ant-menu-item-selected {
        @include selected-nav-item();
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &:hover {
        overflow: visible;
        .tablet-nested-menu {
          display: block;
          overflow: visible;
        }
      }
    }

    .tablet-nested-menu {
      display: none;
      position: absolute;
      top: -16px;
      left: 191px;
      width: 200px;
      height: auto;
      padding: 12px 6px;
      padding-right: 8px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07), 0 3px 20px rgba(0, 0, 0, 0.1);
      z-index: 100;
      overflow: hidden;

      .nested-nav-items {
        background-color: transparent;
        border-radius: 0px;
        color: #000;

        .anticon {
          min-width: 22px;
          min-height: 22px;
          font-size: 20px;
        }

        .ant-menu-title-content {
          margin-left: 10px;
          a {
            color: #000;
          }
        }

        &::after {
          border: none;
        }

        //fill-svg
        &:hover .ant-menu-title-content {
          color: #ff4c30;
          a {
            color: #ff4c30;
          }
          &:active {
            background-color: transparent;
            border-radius: 0px;
          }
        }

        .custom-badge {
          .ant-badge-count {
            top: -8px;
            right: -6px;
            padding: 0 5px;
            box-shadow: none;
            font-size: 12px;
          }
        }

        &:not(:active) {
          border-radius: 0px;
        }

        &.ant-menu-item-selected {
          @include selected-nav-item();

          &:active {
            border-radius: 0px;
          }
        }
      }
    }

    .mobile-sub-items {
      background-color: transparent;
      border-radius: 7px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      .ant-menu-submenu-title {
        @include nav-item();
      }

      &:not(:active) {
        border-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {
          color: #ff4c30;
          a {
            color: #ff4c30;
          }
          &:active {
            background-color: transparent;
            border-radius: 7px;
          }
          .ant-menu-submenu-arrow {
            color: #ff4c30;
          }
        }
      }

      &.ant-menu-submenu-selected {
        background-color: #ff4c30;
        border-radius: 0;
        .ant-menu-submenu-title {
          color: #fff;
          a {
            color: #fff;
          }
          &:active {
            background-color: transparent;
            border-radius: 7px;
          }
          .ant-menu-submenu-arrow {
            color: #fff;
          }
        }

        //nested-menu
        .ant-menu-sub {
          .ant-sub-menu-item-active {
            position: relative;
            a {
              color: #ff4c30;
            }

            &::before {
              content: "";
              position: absolute;
              top: 0px;
              right: 1px;
              width: 2px;
              height: 100%;
              background-color: #ff4c30;
            }
          }
        }
      }

      .ant-menu-sub {
        background: rgb(243, 243, 243);
        .ant-menu-item.ant-menu-item-only-child.nav-items {
          padding-left: 0px !important;
        }
      }
    }

    .btn-logout-wrapper {
      position: absolute;
      bottom: 10px;
      left: 0;
      margin-top: auto;
      padding-right: 8px;

      .ant-menu-title-content {
        margin: 0;

        .btn-logout {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          padding: 0px;
          text-align: left;
          background-color: transparent;
          border-color: transparent;
          font-size: 14px;
          color: #000;

          .anticon {
            vertical-align: baseline;
            color: #000;
            transition: color 0.6s;
          }

          &:hover,
          :focus,
          :active {
            color: #ff4c30;
            .anticon {
              color: #ff4c30;
            }
          }
        }
      }
    }
  }

  &.collapsed-sidebar {
    .main-menu {
      .tablet-nested-menu {
        left: 71px;
      }

      .nav-items {
        position: relative;
        & > .ant-menu-title-content {
          line-height: 0px;
          font-size: 0px;
          overflow: hidden;
          text-overflow: clip;
        }

        &.ant-menu-item-active > .ant-menu-title-content {
          .tooltip-nav-item {
            position: absolute;
            top: 0px;
            left: 82px;
            min-width: 100px;
            height: auto;
            padding: 12px 24px;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07), 0 3px 20px rgba(0, 0, 0, 0.1);
            font-size: 14px;
            line-height: 20px;
            color: #ff4c30;
            z-index: 100;

            &::before {
              content: "";
              position: absolute;
              left: -12px;
              top: calc(22px - 6px);
              border: 6px solid transparent;
              border-right: 6px solid #fff;
            }
          }
        }

        &.btn-logout-wrapper {
          position: absolute;
          padding-left: 20px;
          .ant-menu-title-content {
            margin: 0;
            .btn-logout {
              width: auto;
              span:last-child {
                width: 0;
                margin: 10px;
                overflow: clip;
              }
            }
          }
        }
      }
    }
  }
}
