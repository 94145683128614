@import "antd/dist/antd.css";
@import "./fonts/index.css";
@import "./custom.scss";

@mixin red-btn-style {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff4c30;
  color: #fff;

  &.ant-btn:hover {
    color: #fff;
    background: #f0452b;
    border-color: #ff4c30;
    box-shadow: 0 0 0 0.25rem rgb(#f0452b, 50%);
  }

  &.ant-btn:focus {
    color: #fff;
    background: #f0452b;
    border-color: #ff4c30;
    box-shadow: 0 0 0 0.25rem rgb(#f0452b, 50%);
  }

  &.ant-btn:active {
    color: #fff;
    background: #e64128;
    border-color: transparent;
    box-shadow: 0 0 0 0.25rem rgb(#f0452b, 50%);
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }

  .icon {
    margin-right: 8px;
  }
}

@mixin green-btn-style {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a07b;
  color: #fff;

  &.ant-btn:hover {
    color: #fff;
    background: #10aa86;
    border-color: #00a07b;
    box-shadow: 0 0 0 0.25rem rgb(#10aa86, 50%);
  }

  &.ant-btn:focus {
    color: #fff;
    background: #10aa86;
    border-color: #00a07b;
    box-shadow: 0 0 0 0.25rem rgb(#10aa86, 50%);
  }

  &.ant-btn:active {
    color: #fff;
    background: #1aad8b;
    border-color: transparent;
    box-shadow: 0 0 0 0.25rem rgb(#10aa86, 50%);
  }

  .icon {
    margin-right: 8px;
  }
}

::-webkit-resizer {
  background-color: #666;
}
::-webkit-scrollbar-corner {
  &:hover {
    background-color: #eef1f5;
  }
}
::-webkit-scrollbar-thumb {
  background-color: #737a81cc;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
::-webkit-scrollbar-track {
  &:hover {
    background-color: #dfe0e0;
  }
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

@media screen and (max-width: 750px) {
  ::-webkit-scrollbar {
    width: 0;
  }
}

html {
  font-size: 14px;
}

body {
  background-color: #fff !important;
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  font-size: 14px;
}

a {
  padding: 0;
  font-size: 14px;
}

@supports ((height: max(10px, 20px)) and (padding-top: constant(safe-area-inset-top))) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-left: constant(safe-area-inset-left);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  }
}
@supports ((height: max(10px, 20px)) and (padding-top: env(safe-area-inset-top))) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
  }
}
html[data-keyboardshown][data-with-notch] {
  --safe-area-inset-bottom: 0;
}

:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

@each $color, $value in $theme-colors {
  .icon.#{$color} {
    fill: $value;
  }
}
@each $color, $value in $theme-colors {
  .icon.#{$color}:hover {
    fill: darken($value, 25);
  }
}

//==================SPAN_TEXT_STYLE==================
.span-large-text {
  display: block;
  font-size: 16px;
  // overflow-wrap: break-word;
  // word-wrap: break-word;
  // word-break: break-all;
  // line-break: auto;
  // -webkit-hyphens: auto;
  // -ms-hyphens: auto;
  // hyphens: auto;
  color: #3d3d4e;
}
.span-large-light-text {
  display: block;
  font-size: 16px;
  font-weight: normal;
  // overflow-wrap: break-word;
  // word-wrap: break-word;
  // word-break: break-all;
  // line-break: auto;
  // -webkit-hyphens: auto;
  // -ms-hyphens: auto;
  // hyphens: auto;
  color: #858788;
}
.span-normal-text {
  display: block;
  font-size: 14px;
  // white-space: break-spaces;
  white-space: pre-wrap;
  font-weight: normal;
  // overflow-wrap: break-word;
  // word-wrap: break-word;
  // word-break: break-all;
  // line-break: auto;
  // -webkit-hyphens: auto;
  // -ms-hyphens: auto;
  // hyphens: auto;
  color: #3d3d4e;
}
.span-normal-light-text {
  display: block;
  font-size: 14px;
  white-space: pre-wrap;
  font-weight: normal;
  // overflow-wrap: break-word;
  // word-wrap: break-word;
  // word-break: break-all;
  // line-break: auto;
  // -webkit-hyphens: auto;
  // -ms-hyphens: auto;
  // hyphens: auto;
  color: #858788;
}
.span-small-text {
  display: block;
  font-size: 12px;
  font-weight: normal;
  white-space: pre-wrap;
  // overflow-wrap: break-word;
  // word-wrap: break-word;
  // word-break: break-all;
  // line-break: auto;
  // -webkit-hyphens: auto;
  // -ms-hyphens: auto;
  // hyphens: auto;
  color: #3d3d4e;
}
.span-small-light-text {
  display: block;
  font-size: 12px;
  font-weight: normal;
  white-space: pre-wrap;
  // overflow-wrap: break-word;
  // word-wrap: break-word;
  // word-break: break-all;
  // line-break: auto;
  // -webkit-hyphens: auto;
  // -ms-hyphens: auto;
  // hyphens: auto;
  color: #858788;
}
.color-black {
  color: #3d3d4e;
}

//==========ANT_LAYOUT==========
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-content {
  flex-grow: 1;
  min-height: 0;
  padding: 24px;
}

.table-wrapper {
  height: 100%;
  padding: 12px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 4px 15px rgb(0 0 0 / 4%), 1px 8px 8px rgb(0 0 0 / 4%);
}

.row-pointer {
  cursor: pointer;
}

//==========ANT_BTN==========
.ant-btn {
  .anticon {
    vertical-align: middle !important;
  }
}

.btn-wrapper {
  display: flex;
  justify-content: start;

  .ant-btn {
    margin-right: 8px;
  }
}

.custom-red-btn {
  @include red-btn-style;
}

.custom-green-btn {
  @include green-btn-style;
}

// =============ANT_TABLE================
.table-antd {
  width: 100%;
  height: 100%;

  &.table-isFooter {
    .ant-spin-nested-loading {
      height: calc(100% - 70px);
    }
  }
}
.ant-spin-nested-loading {
  height: 100%;
}
.ant-spin-container {
  height: 100%;
}
.ant-table {
  height: 100%;
  .ant-table-body {
    height: 100%;
  }
}
.ant-table-container {
  height: 100%;
}
.ant-table-pagination.ant-pagination {
  margin: 0;
}

// ============ANT_TABLE_HEADER===============
.ant-table-thead {
  .ant-table-cell {
    white-space: nowrap;
    .ant-table-column-sorters,
    .ant-table-filter-column {
      .ant-table-column-title {
        white-space: nowrap;
      }
    }
  }
}

// =============ANT_TABLE_BODY================
.ant-table-tbody {
  height: 100%;
  tr {
    td {
      overflow: hidden;
      .image-wrapper {
        width: 130px;
        height: 80px;
        border-radius: 7px;
        .ant-image {
          .ant-image-img {
            width: 130px;
            height: 80px;
            border-radius: 7px;
            overflow: hidden;
          }
          .ant-image-mask {
            border-radius: 7px;
          }
        }
      }
    }
  }
}
.btn-wrapper-table {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  cursor: default;

  &.center {
    justify-content: center;
  }
}

//=============ANT_TABLE_FOOTER============
.ant-table-footer {
  .custom-footer {
    display: flex;
    flex-wrap: nowrap;
    padding: 8px 0;
    overflow-x: auto;

    .footer-item {
      display: flex;
      flex-wrap: nowrap;
      margin-right: 16px;

      span:first-child {
        margin-right: 8px;
        white-space: nowrap;
      }
      span:last-child {
        margin-right: 8px;
      }
    }
  }
}

//==========ANT_LABEL======================
.ant-form-item-label {
  text-align: left;
}

//==========ANT_FORM_ITEM==================
.ant-form-item {
  margin-bottom: 17px;
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

//==========ANT_LABEL_REQURED_ICON===============
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  top: 2px;
  right: -12px;
}
.ant-form-item-label > label::after {
  content: none;
}
.kastil-required-mark-optional .ant-form-item-label label::before {
  content: "*";
  display: inline-block;
  position: absolute;
  top: 2px;
  right: -12px;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

//==========ANTICON==================
.ant-upload-text-icon .anticon {
  vertical-align: -0.125em !important;
}

.ant-select-tree-switcher-icon {
  vertical-align: 0.25em !important;
}

//==========ANT_MODAL==================
.ant-modal-header {
  padding: 24px !important;
  padding-bottom: 16px !important;
}
.ant-modal-body {
  .ant-form {
    max-height: calc(100vh - 380px);
    overflow: hidden;
    overflow-y: scroll;
  }
}
@media screen and (max-width: "750px") {
  .ant-modal-body {
    .ant-form {
      max-height: calc(100vh - 200px);
    }
  }
}
.ant-modal-footer {
  padding: 24px !important;
  padding-top: 16px !important;
}

//===============ANT_PRE-libreak-spacesVIEW================
.ant-image-break-spacesview-operations {
  background: #000;
}

//====ANT_DATE_PICKER_DROPDOWN_CONTAINER====
@media screen and (max-width: "750px") {
  .ant-picker-panel-container .ant-picker-panels {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
