.logo-link {
  display: flex;
  align-items: center;
  height: 30px;
  margin-right: auto;

  .logo-link-icon {
    width: 30px;
    height: 30px;
    @media screen and (max-width: 750px) {
      width: 26px;
      height: 26px;
    }
  }

  .logo-link-title {
    margin-left: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #ddd;
  }
}
