$badge-shadow: 0 0 0 1px #ff4d4f;

.app-header.ant-layout-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  background-color: #000c17;

  .logo-link-orders {
    position: relative;
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 20px;
    color: #e8e8e8;

    &.active {
      color: #ff4c30;
    }

    .custom-badge {
      position: absolute;

      .ant-badge-count {
        top: -10px;
        right: -22px;
        min-width: 14px;
        height: 14px;
        padding: 0 2px;
        box-shadow: none;
        font-size: 12px;
        line-height: 14px;
        box-shadow: $badge-shadow;
        @media screen and (max-width: 750px) {
          top: -8px;
          right: -20px;
          box-shadow: $badge-shadow;
        }
      }
    }
  }

  .user-login-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user-icon {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      @media screen and (max-width: 750px) {
        width: 26px;
        height: 26px;
        margin-right: 0px;
      }
    }

    .user-name {
      font-size: 14px;
      line-height: 16px;
      color: #ddd;
    }
  }
}
