.app {
  position: relative;
  min-width: 1px;
  min-height: 1px;
  height: 100vh;
  overflow: hidden;

  .ant-layout {
    background: #f7f7f7;

    .layout-content {
      height: 100%;

      .site-layout-background {
        height: 100%;
      }
    }
  }

  &-content {
    position: relative;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    margin-top: 50px;
    overflow: hidden;

    &-body {
      height: 100%;
      padding: 15px 20px;

      background-color: #f7f8fa;
    }
  }
}
