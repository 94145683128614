@font-face {
  font-family: "Roboto";
  src: url("./Roboto/Roboto-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
