.login-page-container {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  padding-bottom: 24px;
  background-image: url(../../images/login-page.png);
  // background-blend-mode: overlay;
  // background-repeat: no-repeat;
  // background-position: center center;
  background-size: 100% 100%;
  overflow: hidden;

  .logo-square {
    position: absolute;
    opacity: 0.17;
    animation: animate 12s linear infinite;
    animation-delay: 13s;
    border-radius: 31px;
    box-shadow: 2px 3px 15px rgb(158, 28, 19);
    img {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes animate {
    0%,
    100% {
      transform: translateY(-60px);
    }
    50% {
      transform: translateY(100px);
    }
  }

  .logo-square:nth-child(1) {
    top: 590px;
    right: calc(50% - 320px);
    animation-delay: -2s;
    width: 110px;
    height: 100px;
    transition: 0.3s ease-in-out;

    &:hover {
      right: calc(50% - 335px);
      width: 140px;
      height: 80px;
      transition: 0.3s ease-in-out;
    }
  }

  .logo-square:nth-child(2) {
    top: 320px;
    left: calc(50% - 350px);
    animation-delay: -4s;
    width: 120px;
    height: 120px;
    transition: 0.4s ease-in-out;

    &:hover {
      left: calc(50% - 375px);
      width: 170px;
      height: 120px;
      transition: 0.3s ease-in-out;
    }
  }
  .logo-square:nth-child(3) {
    top: 100px;
    left: 54%;
    animation-delay: -8s;
    width: 80px;
    height: 70px;
    transition: 0.3s ease-in-out;

    &:hover {
      top: 85px;
      left: calc(54% + 10px);
      width: 60px;
      height: 100px;
      transition: 0.3s ease-in-out;
    }
  }

  .login-content {
    padding: 30px 60px;
    border-radius: 10px;
    background-color: #ffffff1a; //#d8d8d83d
    backdrop-filter: blur(8px);
    box-shadow: 8px 15px 35px rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.45);
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    form {
      width: 360px;
      padding: 0;
    }

    .title-block {
      text-align: center;
      margin-bottom: 100px;

      .logo {
        width: 298px;
        height: 68px;
        margin-bottom: 22px;
      }

      &-text {
        display: block;
        margin: 0;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        text-align: center;
        color: #423f41;
      }
    }
  }

  .footer {
    margin-top: auto;

    .copyright {
      padding: 0 60px;
      margin: 0;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      line-height: 22px;
      color: #6a7780;
    }
  }

  //=============INPUT============

  .login-form-group {
    margin-bottom: 16px;

    .ant-input-affix-wrapper {
      padding: 8px 12px;
      background-color: #ffffff23;
      border: 1px solid rgba(255, 255, 255, 1);
      box-shadow: 1px 1px 5px rgb(0 0 0 / 9%);
      .ant-input {
        background-color: transparent;
        &::placeholder {
          color: #ff4c30;
        }
      }
      &:hover {
        background-color: #ffffff21;
      }
    }

    .ant-input-affix-wrapper .login-form-icon svg {
      width: 16px;
      height: 16px;
      margin-right: 2px;
      fill: #ff4c30;
    }

    .ant-input-suffix .anticon-eye.ant-input-password-icon svg {
      fill: #ff4c30;
    }

    &-checkbox {
      margin: 0;
      margin-top: 25px;

      .form-check-label {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  //input-focus
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: #ff4c30;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(255, 76, 48, 20%);
  }

  //input-hover
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #ff4c30 !important;
    border-right-width: 1px;
    z-index: 1;
  }

  //checkbox-checked-border/background
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.remember-checkbox {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ff4c30;
      border-color: #ff4c30;
    }

    .ant-checkbox-checked::after {
      border: 1px solid #ff4c30;
    }
  }

  //checkbox-not-checked-border
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ff4c30;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: rgb(0, 0, 0);
    font-size: 14px;
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  // input:-webkit-autofill:not(:focus) {
  //   -webkit-text-fill-color: rgba(0, 0, 0);
  // }

  //ERROR_MESSAGE
  .auth-incorrect-message {
    color: #c34a4a;
  }

  //Button
  .btn-login {
    height: 40px;
    margin-top: 25px;
    background-color: #ff4c30;
    box-shadow: none;
    border: 1px solid #ff4c30;
    border-radius: 2px;
    font-size: 16px;
    font-weight: normal;
    color: #fff;

    &:hover {
      border-color: #ff4c30;
      background-color: #ff4c30;
      box-shadow: none;
      color: #fff;
    }
    &:focus {
      border-color: #ff4c30;
      background-color: #ff4c30;
      box-shadow: 0 0 0 0.2rem #ff4c308e;
      color: #fff;
    }
    &:active {
      border-color: #ff4c30;
      background-color: #ff4c30;
      box-shadow: 0 0 0 0.2rem #ff4c308e;
      color: #fff;
    }
  }

  .btn-login:disabled {
    background-color: #ffffff31;
    border: none;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 9%);
    color: #423f41;
    &:hover {
      background-color: #ffffff31;
      border: none;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 9%);
      color: #423f41;
    }
  }
}

@media screen and(max-width: 750px) {
  .login-page-container {
    padding-top: 100px;
    background-image: url(../../images/mobile-login-page.png);

    .logo-square {
      display: none;
    }

    .login-content {
      width: 100%;
      background-color: transparent;
      box-shadow: none;
      border: none;
      backdrop-filter: none;
    }

    .login-content form {
      width: 100%;

      .title-block {
        margin-bottom: 60px;
        .logo {
          width: 210px;
          height: 56px;
        }

        .title-block-text {
          font-size: 14px;
        }
      }
    }

    .btn-login {
      font-size: 14px;
    }
  }
}
