.ant-btn.btn-toggler.trigger {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-left: -3px;
  padding: 0;
  background-color: transparent;
  border: none;
  color: #fff;

  @media screen and (max-width: 750px) {
    margin-right: 10px;
  }
}
