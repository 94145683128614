//=======DRAWER==========
.ant-drawer.drawer-sidebar {
  height: 100%;
  overflow: hidden;

  .ant-drawer-content-wrapper {
    box-shadow: none !important;
    .ant-drawer-content {
      background-color: transparent;
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          width: 190px;
          height: 50px;
          padding: 0;
          border: 0px solid transparent;
          border-radius: 0px;
          background-color: #000c17;

          .ant-drawer-title {
            display: flex;
            align-items: center;

            height: 100%;
            padding: 0 24px;
            padding-right: 0px;
          }
        }

        .ant-drawer-body {
          display: flex;
          flex-wrap: nowrap;
          padding: 0px;
          .custom-drawer-close-mask {
            flex-grow: 1;
          }
        }
      }
    }
  }
}

.drawer-btn-container {
  text-align: right;
  .ant-btn:first-child {
    margin-right: 8px;
  }
}
